import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { faBullhorn, faPeopleGroup } from '@fortawesome/pro-solid-svg-icons';
import {
  type SideNavigation,
  type SideNavigationGroupItem,
  SideNavigationItemTypes,
} from '../../types';
import useNavigationData from './useNavigationData';
import useNavigationDataShared from './useNavigationDataShared';

export default async () => {
  const { locale } = useI18n();

  const { communityMenuData } = useNavigationDataShared();

  const businessMenuData = computed<SideNavigationGroupItem>(() => ({
    key: `${locale.value}__business`,
    label: 'general/menu/block/business',
    translate: true,
    items: [
      {
        key: `${locale.value}__game-studios`,
        label: 'general/menu/item/game_studios',
        translate: true,
        icon: faBuilding,
        type: SideNavigationItemTypes.EXTERNAL,
        data: {
          url: 'https://www.g-portal.com/business/',
        },
      },
      {
        key: `${locale.value}__partner`,
        label: 'general/menu/item/partner',
        translate: true,
        icon: faPeopleGroup,
        type: SideNavigationItemTypes.NUXT,
        data: {
          url: `/${locale.value}/partner`,
        },
      },
      {
        key: `${locale.value}__case-studies`,
        label: 'general/menu/item/case_studies',
        translate: true,
        icon: faBullhorn,
        type: SideNavigationItemTypes.EXTERNAL,
        data: {
          url: 'https://www.g-portal.com/business/case-studies/',
        },
      },
    ],
  }));

  const { productMenuData } = await useNavigationData();

  const firstLevelMenu = computed(() => ({
    uplink: null,
    items: [
      productMenuData.value,
      communityMenuData.value,
      businessMenuData.value,
    ],
  }));

  return {
    firstLevelMenu,
  };
};
