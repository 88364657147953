<template>
  <ul>
    <li v-for="navGroupItem in firstLevelMenu.items" :key="navGroupItem.key">
      <navigation-sidebar-link-group
        :nav-group-item="navGroupItem"
        :hide-group-label="firstLevelMenu.items.length === 1"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import useNavigationDataAnonymous from '../features/useNavigationDataAnonymous';

const { firstLevelMenu } = await useNavigationDataAnonymous();
</script>

<style scoped></style>
